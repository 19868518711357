<template lang="">
  <Card>
    <template #header> Görüşler </template>
    <div class="form-group">
      <label for="">Operasyon Görüşleri</label>
      <textarea
        name=""
        id=""
        rows="4"
        class="form-control"
        v-model="gorus"
      ></textarea>
    </div>
    <div class="d-flex justify-content-end">
      <button class="btn btn-success">Kaydet</button>
    </div>
  </Card>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      gorus: "",
    };
  },
  created(){
    this.getGorusler()
  },
  methods: {
    getGorusler() {
      this.$dbFunctions.get("advert/opinions?user_token="+ this.myUser+"&advert_id=" + this.$route.params.id).then((response)=>{
        this.gorus=response.response.data[1].operations_comments
      })
    },
  },

  computed: {
    ...mapGetters(["myUser"]),
  },
};
</script>
<style lang=""></style>
